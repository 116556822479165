import { FaTwitter, FaTelegramPlane, FaEnvelope, FaMediumM,  } from "react-icons/fa";

const socialLists = [
	{
		id: "0",
		links: "https://twitter.com/avnrich",
		icon: <FaTwitter/>,
	},
	{
		id: "1",
		links: "https://t.me/AVNRich_Chat",
		icon: <FaTelegramPlane/>,
	},
	{
		id: "2",
		links: "mailto:info@avnrich.shop",
		icon: <FaEnvelope />,
	},
	{
		id: "3",
		links: "https://medium.com/@avnrich",
		icon: <FaMediumM />,
	},
	 
];

export default socialLists;
