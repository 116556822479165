import { createTheme } from "@mui/material";

const yellow = "#bbbfbe";
const orange = "#F28F21";

export const theme = createTheme({
  palette: {
    divider: "white",
    common: {
      yellow,
      orange,
    },
    background: {
      paper: "#1c201f",
      default: "#1c201f",
    },
    primary: {
      main: "rgb(46 58 55)",
    },
    secondary: {
      main: "#1c201f",
    },
  },
  typography: {
    body1: {
      fontSize: "18px",
    },
    body2: {
      fontSize: "12px",
    },
    subtitle1: {
      fontSize: "14px",
    },
    h5: {
      fontSize: "28px",
    },
  },
});
