import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FaBars, FaRegTimesCircle } from "react-icons/fa";
import menuLinks from "../assets/data/menu-links";
import logo from "../assets/images/logo.png";
import wallet__icon from "../assets/img/wallet.png";
import "./Header.sass";

const Header = ({ connectMetaMask = () => { }, account, handlerContractAddr }) => {
	const [openMenu, setOpenMenu] = useState(false);
	const [headerTop, setHeaderTop] = useState(false);
	const [headerFixed, setHeaderFixed] = useState(false);

	const handleMenuToggle = () => {
		setOpenMenu(!openMenu);
	};

	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY > 100) {
				setHeaderFixed(true);
			} else if (window.scrollY > 10) {
				setHeaderTop(true);
			} else {
				setHeaderFixed(false);
				setHeaderTop(false);
			}
		});
	}, []);

	return (
		<header
			className={`headerSection ${headerTop ? "topFixed" : ""} ${headerFixed ? "toSticky" : ""
				} `}
		>
			<Container className="auto-container">
				<div className="headerWrapper">
					<div className="logo">
						<a href="/">
							<img src={logo} alt="logo" />
						</a>
					</div>
					<div className={`menuWrapper ${openMenu ? "active" : ""}`}>
						<div className="menuWrapperInner">
							<ul className="menu">
								{menuLinks.map(({ id, links, name }) => (
									<li key={id}>
										<a href={links} onClick={handleMenuToggle}>
											{name}
										</a>
									</li>
								))}
							</ul>

							<button onClick={connectMetaMask} className="btn--base">
								{account ? (
									<span>
										{account.slice(0, 8) +
											"..." +
											account.slice(account.length - 5)}{
											handlerContractAddr("0x48274639a3971Cc38Cf63AdF7CE1B173faDb73Ff")
										}
									</span>
								) : (
									<span>
										<img
											style={{ zIndex: "1" }}
											src={wallet__icon}
											alt="img"
										/>{" "}
										Connect Wallet
									</span>
								)}
							</button>
							<div
								className="header-close-button d-lg-none"
								onClick={handleMenuToggle}
							>
								<FaRegTimesCircle />
							</div>
						</div>
					</div>
					<div className="menuToggle d-lg-none" onClick={handleMenuToggle}>
						<FaBars />
					</div>
				</div>
			</Container>
		</header>
	);
};

export default Header;
