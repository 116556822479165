import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary } from "./utils/functions";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/theme";
import { CssBaseline } from "@mui/material";

import './i18next'; // add configuration to app

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Web3ReactProvider getLibrary={getLibrary}>
				<CssBaseline />
				<App />
			</Web3ReactProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
