import React, { useRef } from "react";
import { BiCopy } from "react-icons/bi";
import logo from "../assets/images/logo.png";
import Countdown from "./Countdown";
const About = ({
	withdraw,
	account
}) => {
	const ref = useRef();
	const copyFunc = () => {
		var copyText = ref.current;
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		navigator.clipboard.writeText(copyText.value);
		alert("Successfully Copied: " + copyText.value);
	};

	return (
		<section className="about-section">
			<div className="container">
				<div className="about-logo">
					<a href="/#">
						<img src={logo} alt="logo" />
					</a>
					<h1 className="title">AVNRICH</h1>
				</div>
				<div className="about-content">
					<div className="content">
						<p>
							Only addresses who took part in the airdrop and performed the mandatory tasks are eligible to get $AVN airdrop rewards. Participants will be able to claim their tokens from 4th November until 4th December, 2022 at 4pm UTC. The claim button will be removed after the given deadlines.
						</p>
					</div>
					<h6 className="subtitle">
						Make sure you've added the AVN contract address to your wallet
					</h6>
					<div className="copy-addres-area">
						<div className="mb-2">Contract Addres</div>
						<div className="contact-address">
							<input
								ref={ref}
								type="text"
								value="0xbf151f63d8d1287db5fc7a3bc104a9c38124cdeb"
								readOnly
							/>
							<button type="button" onClick={() => copyFunc()}>
								<BiCopy />
							</button>
						</div>
					</div>
					<div className="countdown-area my-5 py-lg-4">
						<h4 className="my-3">Nov 4, 2022</h4>
						<Countdown
							countDownDate={new Date("Dec 4, 2022 15:37:25").getTime()}
						/>
					</div>
					<div className="copy-addres-area mb-5">
						<div className="mb-2">
							Claim button will only be visible to those addresses that
							are listed on our system .
						</div>
						<div className="contact-address">
							<input
								readOnly
								value={account ? account : ""}
								type="text"
								placeholder="Connect wallet to claim"
							/>
							<button onClick={withdraw} className="claim-btn" type="button">
								Claim
							</button>
						</div>
					</div>
					<div className="mb-2">How to claim your airdrop reward ?</div>
					<ol>
						<li> First, Open Menu and Connect wallet then add AVN contract address on your wallet
</li>
						<li>Paste your BSC address in the box above that you used to join the airdrop.
</li>
						<li>Next, press the claim button.</li>
					</ol>
				</div>
			</div>
		</section>
	);
};

export default About;
